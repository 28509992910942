import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Collapse, useMediaQuery, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const BetaBanner = ({ onDismiss }) => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const bannerDismissed = localStorage.getItem('betaBannerDismissed');
        if (bannerDismissed) {
            setIsVisible(false);
            onDismiss();
        }
    }, [onDismiss]);

    const handleDismiss = () => {
        setIsVisible(false);
        localStorage.setItem('betaBannerDismissed', 'true');
        onDismiss();
    };

    if (!ready) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
                <CircularProgress size={24} />
            </Box>
        );
    }

    return (
        <Collapse in={isVisible}>
            <Box
                sx={{
                    bgcolor: 'warning.main',
                    color: 'warning.contrastText',
                    py: isSmallScreen ? 0.3 : 0.5,
                    px: isSmallScreen ? 1 : 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 9999,
                }}
            >
                <Typography variant={isSmallScreen ? "caption" : "body2"} sx={{ flexGrow: 1, textAlign: 'center' }}>
                    {t('beta.message')}
                </Typography>
                <IconButton
                    size="small"
                    onClick={handleDismiss}
                    sx={{ color: 'warning.contrastText' }}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Box>
        </Collapse>
    );
};

export default BetaBanner;