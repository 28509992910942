// @Definition: LanguageProvider component that loads and sets up the i18n language support for the application.
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadLanguage = async () => {
      await i18n.loadLanguages(i18n.language);
      setLoading(false);
    };
    loadLanguage();
  }, [i18n]);

  if (loading) {
    return <div>Loading...</div>; // O un componente di loading più elaborato
  }

  return children;
};

export default LanguageProvider;
