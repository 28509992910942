// @Definition: Defines the NavigationBar component that provides a responsive navigation bar with links, search, and language selection.
import React, { useState, useRef, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Select,
  MenuItem,
  IconButton,
  InputBase,
  Avatar,
  Menu,
  Tooltip,
  FormControl,
  useMediaQuery,
  useTheme,
  CircularProgress
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import TodayIcon from '@mui/icons-material/Today';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LanguageIcon from '@mui/icons-material/Language';
import { useCalendar } from '../contexts/CalendarContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import CannyFeedback from './CannyFeedback';
import { useTranslation } from 'react-i18next';
import Logo from './Logo';

const Search = styled('div')(({ theme, isExpanded }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: isExpanded ? '300px' : '200px',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shortest,
  }),
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: '100%',
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    paddingTop: 5,
    paddingBottom: 5,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  color: theme.palette.common.white,
  '& .MuiSvgIcon-root': {
    color: theme.palette.common.white,
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  minHeight: 'auto',
}));

const NavigationBar = ({ isBannerVisible }) => {

  const calendarContext = useCalendar();

  const {
    currentYear,
    selectedCategory,
    categories,
    currentDate,
    handleYearChange,
    handleCategoryChange,
    handleGoToCurrentYear,
    handleOpenDialog
  } = calendarContext;

  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth();
  const { t, i18n, ready } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const searchInputRef = useRef(null);
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmall = useMediaQuery(theme.breakpoints.between('sm', 'md'));


  useEffect(() => {
    if (!selectedCategory && categories.length > 0) {
      handleCategoryChange('all');
    }
  }, [categories, selectedCategory, handleCategoryChange]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchInputRef.current && !searchInputRef.current.contains(event.target)) {
        setIsSearchExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    // Implement theme change logic here
  };

  const changeLanguage = () => {
    i18n.changeLanguage(i18n.language === 'en' ? 'it' : 'en');
  };

  const handleSearchFocus = () => {
    setIsSearchExpanded(true);
  };

  const handleSearchBlur = () => {
    if (searchInputRef.current && searchInputRef.current.value === '') {
      setIsSearchExpanded(false);
    }
  };

  const isCalendarPage = location.pathname === '/calendar';
  const isCalendarsPage = location.pathname === '/calendars';

  if (!ready) {
    return (
        <AppBar position="fixed" color="primary" elevation={4} sx={{
          top: isBannerVisible ? '24px' : 0,
          transition: 'top 0.3s',
        }}>
          <Toolbar>
            <CircularProgress color="inherit" />
          </Toolbar>
        </AppBar>
    );
  }

  const renderYearNavigation = () => (
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        mr: isXSmall ? 2 : 4,
        ml: isXSmall ? 2 : 4,
        '& .MuiIconButton-root': {
          padding: isXSmall ? '4px' : '8px',
        },
        '& .MuiSvgIcon-root': {
          fontSize: isXSmall ? '1rem' : '1.5rem',
        },
      }}>
        <IconButton
            color="inherit"
            onClick={() => handleYearChange(-1)}
            disabled={currentYear <= currentDate.getFullYear() - 2}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant={isXSmall ? "body1" : "h6"} sx={{ mx: isXSmall ? 1.5 : 2 }}>
          {currentYear}
        </Typography>
        <Tooltip title="Go to current year">
          <IconButton color="inherit" onClick={handleGoToCurrentYear}>
            <TodayIcon />
          </IconButton>
        </Tooltip>
        <IconButton
            color="inherit"
            onClick={() => handleYearChange(1)}
            disabled={currentYear >= currentDate.getFullYear() + 7}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
  );

  const renderAddEventButton = () => (
      <Tooltip title={t('nav.addEvent')}>
        <IconButton
            color="inherit"
            onClick={() => handleOpenDialog()}
            sx={{
              ml: isXSmall ? 1 : 2,
              padding: isXSmall ? '4px' : '8px',
              '& .MuiSvgIcon-root': {
                fontSize: isXSmall ? '1.2rem' : '1.5rem',
              },
            }}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
  );

  return (
      <AppBar position="fixed" color="primary" elevation={4} sx={{
        top: isBannerVisible ? '24px' : 0,
        transition: 'top 0.3s',
      }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="logo" onClick={() => navigate('/')}>
            <Logo width={32} height={32} />
          </IconButton>
          {!isXSmall && (
              <Typography variant="h6" sx={{ ml: 2, fontSize: isSmall ? '1rem' : '1.25rem' }}>
                {t('appName')}
              </Typography>
          )}
          <Box sx={{ flexGrow: 1 }} />

          {isCalendarPage && (
              <>
                {renderYearNavigation()}
                {renderAddEventButton()}
                {!isSmall && !isXSmall && (
                    <FormControl sx={{ minWidth: 120, ml: 2 }} size="small">
                      <StyledSelect
                          labelId="category-filter-label"
                          id="category-filter"
                          value={selectedCategory || 'all'}
                          label="Category"
                          onChange={(e) => handleCategoryChange(e.target.value)}
                          IconComponent={KeyboardArrowDownIcon}
                          displayEmpty
                      >
                        <StyledMenuItem value="all">All</StyledMenuItem>
                        {categories.map((category) => (
                            <StyledMenuItem key={category._id} value={category._id}>
                              {category.name}
                            </StyledMenuItem>
                        ))}
                        {categories.length === 0 && (
                            <StyledMenuItem disabled>No categories available</StyledMenuItem>
                        )}
                      </StyledSelect>
                    </FormControl>
                )}
              </>
          )}

          {!isCalendarsPage && (
              <Tooltip title={t('nav.calendarHetView')}>
                <IconButton
                    color="inherit"
                    onClick={() => navigate('/calendars')}
                    sx={{ ml: 2 }}
                >
                  <CalendarTodayIcon />
                </IconButton>
              </Tooltip>
          )}

          {!isXSmall && (
              <Search isExpanded={isSearchExpanded}>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                    onFocus={handleSearchFocus}
                    onBlur={handleSearchBlur}
                    inputRef={searchInputRef}
                />
              </Search>
          )}

          {!isSmall && !isXSmall && (
              <>
                <IconButton color="inherit" onClick={toggleTheme}>
                  {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
                <StyledSelect
                    value={i18n.language}
                    onChange={(e) => i18n.changeLanguage(e.target.value)}
                    sx={{ ml: 2 }}
                    IconComponent={KeyboardArrowDownIcon}
                >
                  <StyledMenuItem value="en">EN</StyledMenuItem>
                  <StyledMenuItem value="it">IT</StyledMenuItem>
                </StyledSelect>
              </>
          )}
          <CannyFeedback />
          <Tooltip title="Account settings">
            <IconButton
                onClick={handleMenu}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={Boolean(anchorEl) ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
            >
              <Avatar sx={{ width: 32, height: 32 }}>
                {user ? user.name : 'U'}
              </Avatar>
            </IconButton>
          </Tooltip>

          <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={Boolean(anchorEl)}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <StyledMenuItem onClick={() => navigate('/profile')}>
              Profile
            </StyledMenuItem>
            {(isXSmall || isSmall) && (
                <>
                  <StyledMenuItem onClick={toggleTheme}>
                    {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                    <Typography sx={{ ml: 2 }}>{isDarkMode ? 'Light Mode' : 'Dark Mode'}</Typography>
                  </StyledMenuItem>
                  <StyledMenuItem onClick={changeLanguage}>
                    <LanguageIcon />
                    <Typography sx={{ ml: 2 }}>
                      {i18n.language === 'en' ? 'Italiano' : 'English'}
                    </Typography>
                  </StyledMenuItem>
                </>
            )}
            <StyledMenuItem onClick={handleLogout}>
              Logout
            </StyledMenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
  );
};

export default NavigationBar;