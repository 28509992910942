// @Definition: A React component that provides a floating feedback button and a dialog for users to submit feedback.
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Button, Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Select, MenuItem, FormControl, InputLabel,
    Fab, Tooltip
} from '@mui/material';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { apiV1 } from '../services/api';

const CannyFeedback = () => {
    const [open, setOpen] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const location = useLocation();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await apiV1.get('/feedback/categories');
                setCategories(response.data);
                if (response.data.length > 0) {
                    setSelectedCategory(response.data[0].id);
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit = async () => {
        try {
            await apiV1.post('/feedback/submit', {
                title: 'Feedback from Year at a Glance',
                details: feedback,
                categoryId: selectedCategory,
                metadata: {
                    page: location.pathname,
                    url: window.location.href,
                    userAgent: navigator.userAgent
                }
            });
            // Show success message
            handleClose();
        } catch (error) {
            console.error('Error submitting feedback:', error);
            // Show error message
        }
    };

    return (
        <>
            <Tooltip title="Give Feedback" placement="right">
                <Fab
                    color="primary"
                    aria-label="feedback"
                    onClick={handleOpen}
                    sx={{
                        position: 'fixed',
                        left: '-80px',
                        width: '190px',
                        height: '30px',
                        top: '50%',
                        transform: 'translateY(-50%) rotate(90deg)',
                        borderRadius: '16px 16px 0 0',
                        '& .MuiSvgIcon-root': {
                            transform: 'rotate(90deg)',
                        },
                    }}
                >
                    <FeedbackIcon sx={{
                       marginRight: '8px',
                    }} />
                    Beta Feedback
                </Fab>
            </Tooltip>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Provide Feedback</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="category-select-label">Category</InputLabel>
                        <Select
                            labelId="category-select-label"
                            value={selectedCategory}
                            label="Category"
                            onChange={(e) => setSelectedCategory(e.target.value)}
                        >
                            {categories.map((category) => (
                                <MenuItem key={category.id} value={category.id}>
                                    {category.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="feedback"
                        label="Your Feedback"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CannyFeedback;