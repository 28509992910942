// @Definition: Displays a cookie banner at the bottom of the page, allowing users to accept or reject cookies and store their preference.
import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const cookiesAccepted = localStorage.getItem('cookiesAccepted');
        if (!cookiesAccepted) {
            setShowBanner(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        setShowBanner(false);
    };

    if (!showBanner) return null;

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                bgcolor: 'background.paper',
                boxShadow: 3,
                p: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Typography variant="body2" sx={{ mr: 2 }}>
                {t('cookies.message')}
            </Typography>
            <Box>
                <Button color="primary" onClick={handleAccept} sx={{ mr: 1 }}>
                    {t('cookies.accept')}
                </Button>
                <Link href="/terms-and-privacy" color="primary" underline="always">
                    {t('cookies.learnMore')}
                </Link>
            </Box>
        </Box>
    );
};

export default CookieBanner;