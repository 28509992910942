// @Definition: Provides a MessageContext and related hooks for displaying and hiding messages in the yearataglance-frontend application.
import React, { createContext, useState, useContext, useCallback } from 'react';

const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
    const [message, setMessage] = useState(null);

    // Avvolgi showMessage in useCallback per stabilizzarlo
    const showMessage = useCallback((text, severity = 'info') => {
        setMessage({ text, severity });
    }, []);

    const hideMessage = useCallback(() => {
        setMessage(null);
    }, []);

    return (
        <MessageContext.Provider value={{ message, showMessage, hideMessage }}>
            {children}
        </MessageContext.Provider>
    );
};

export const useMessage = () => useContext(MessageContext);