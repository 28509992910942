// @Definition: A reusable React component that displays a message snackbar with customizable severity and auto-hide duration.
import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useMessage } from '../contexts/MessageContext';

const MessageSnackbar = () => {
    const { message, hideMessage } = useMessage();

    if (!message) return null;

    return (
        <Snackbar
            sx={{marginTop: 10}}
            open={!!message}
            autoHideDuration={6000}
            onClose={hideMessage}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert onClose={hideMessage} severity={message.severity} sx={{ width: '100%' }}>
                {message.text}
            </Alert>
        </Snackbar>
    );
};

export default MessageSnackbar;