// @Definition: Exports a function to create an API instance with request/response interceptors and event-related API calls.
import axios from 'axios';

const createApi = (version = 'v1') => {
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL  || `http://localhost:5001/api/${version}`,
    });

    api.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    api.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                window.location = '/login';
            }
            return Promise.reject(error);
        }
    );

    return api;
};

export const apiV1 = createApi('v1');

// Event-related API calls
apiV1.getEvents = (year, categoryId) => apiV1.get(`/events?year=${year}${categoryId ? `&categoryId=${categoryId}` : ''}`);
apiV1.createEvent = (eventData) => apiV1.post('/events', eventData);
apiV1.updateEvent = (eventId, eventData) => apiV1.patch(`/events/${eventId}`, eventData);
apiV1.deleteEvent = (eventId) => apiV1.delete(`/events/${eventId}`);

apiV1.getHeatmapData = (year) => apiV1.get(`/events/heatmap?year=${year}`);

// Category-related API calls
apiV1.getCategories = () => apiV1.get('/categories');
apiV1.createCategory = (categoryData) => apiV1.post('/categories', categoryData);
apiV1.updateCategory = (categoryId, categoryData) => apiV1.patch(`/categories/${categoryId}`, categoryData);
apiV1.deleteCategory = (categoryId) => apiV1.delete(`/categories/${categoryId}`);

// Beta functionality
apiV1.requestBetaAccess = (email) => apiV1.post('/auth/request-beta', { email });
apiV1.registerBeta = (userData) => apiV1.post('/auth/register-beta', userData);

// Password reset API calls
apiV1.requestPasswordReset = (email) => apiV1.post('/auth/forgot-password', { email });
apiV1.resetPassword = (token, newPassword) => apiV1.post('/auth/reset-password', { token, newPassword });


// export const apiV2 = createApi('v2');