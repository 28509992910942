// @Definition: Defines the theme for the yearataglance-frontend project, including color palette, typography, and heatmap settings.
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    heatmap: {
        light: '#959595',
        lighthard: '#6c6c6c',
        normal: '#3c739a',
        intense: '#5eec1a',
        hot: '#f19000',
        superhot: '#1e6c08',
    },
    palette: {
        primary: {
            main: '#2c3e50',
            light: '#3e5771',
            dark: '#1a2530',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#e74c3c',
            light: '#ff6b5b',
            dark: '#b03a2e',
            contrastText: '#ffffff',
        },
        background: {
            default: '#ecf0f1',
            paper: '#ffffff',
        },
        text: {
            primary: '#2c3e50',
            secondary: '#7f8c8d',
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h1: {
            fontSize: '2.5rem',
            fontWeight: 500,
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 500,
        },
        h3: {
            fontSize: '1.8rem',
            fontWeight: 500,
        },
        h4: {
            fontSize: '1.5rem',
            fontWeight: 500,
        },
        h5: {
            fontSize: '1.3rem',
            fontWeight: 500,
        },
        h6: {
            fontSize: '1.1rem',
            fontWeight: 500,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    textTransform: 'none',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                    boxShadow: '0px 1px 2px rgba(0,0,0,0.1)',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                },
            },
        },
        // Nuove personalizzazioni per Select, MenuItem e OutlinedInput
        MuiSelect: {
            styleOverrides: {
                select: {
                    paddingTop: 5,
                    paddingBottom: 5,
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    minHeight: 'auto',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                    },
                },
                notchedOutline: {
                    borderColor: 'transparent',
                },
            },
        },
    },
});

export default theme;