import React, {createContext, useState, useContext, useCallback, useMemo} from 'react';

const CalendarContext = createContext(null);

export const useCalendar = () => useContext(CalendarContext);

export const CalendarProvider = ({ children }) => {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [categories, setCategories] = useState([]);
    const currentDate = useMemo(() => new Date(), []);
    const [haveToOpenDialog, setHaveToOpenDialog] = useState(false);

    const handleYearChange = useCallback((increment) => {
        setCurrentYear(prevYear => {
            const newYear = prevYear + increment;
            if (newYear >= currentDate.getFullYear() - 2 && newYear <= currentDate.getFullYear() + 7) {
                return newYear;
            }
            return prevYear;
        });
    }, [currentDate]);

    const handleCategoryChange = useCallback((categoryId) => {
        setSelectedCategory(categoryId);
    }, []);

    const handleGoToCurrentYear = useCallback(() => {
        setCurrentYear(currentDate.getFullYear());
    }, [currentDate]);

    const handleOpenDialog = useCallback(() => {
        console.log('open dialog');
        setHaveToOpenDialog(true);
    }, []);

    const value = {
        currentYear,
        selectedCategory,
        categories,
        currentDate,
        setCategories,
        handleYearChange,
        handleCategoryChange,
        handleGoToCurrentYear,
        handleOpenDialog,
        haveToOpenDialog
    };

    return <CalendarContext.Provider value={value}>{children}</CalendarContext.Provider>;
};