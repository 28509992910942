import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const languageMap = {
    'en-GB': 'en',
    'en-US': 'en',
    // Aggiungi qui altre mappature se necessario
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        supportedLngs: ['en', 'it'], // Aggiungi qui tutte le lingue supportate
        debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false
        },
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupSessionStorage: 'i18nextLng',
            caches: ['localStorage', 'cookie'],
        },
        load: 'languageOnly', // Carica solo la parte principale della lingua (es. 'en' invece di 'en-GB')
        lowerCaseLng: true,
        nonExplicitSupportedLngs: true,
        returnObjects: true,
        cleanCode: true,
    });

// Aggiungi un middleware per gestire la mappatura delle lingue
i18n.on('languageChanged', (lng) => {
    const mappedLng = languageMap[lng] || lng;
    if (mappedLng !== lng) {
        i18n.changeLanguage(mappedLng);
    }
});

export default i18n;